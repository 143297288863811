<template>
  <div id="app">
    <div class="body fixed mobile">
      <!-- class: fixed, mobile -->
      <!-- Loading Spinner -->
      <!-- <div class="spinner"><div><div></div></div></div> -->
      <!-- Header -->
      <section class="header point_history">
        <a class="icon iconButton third" @click.prevent="goBackTo">
          <font-awesome-icon
            icon="fa-solid fa-chevron-left"
            size="lg"
          ></font-awesome-icon>
        </a>
        <span class="fL">交易紀錄</span>
        <div class="icon iconButton"></div>
      </section>
      <!-- Main -->
      <section class="main point_history">
        <!-- Tabs -->
        <div class="tabs line fM rounded">
          <a
            v-if="enableConsumptionRecord" 
            class="tab flexV width center"
            @click.prevent="$router.push('/payment-record')"
          >
            <span class="fS">消費紀錄</span>
          </a>
          <div v-if="enableConsumptionRecord" class="lineV"></div>
          <a class="tab flexV width center active" @click.prevent>
            <span class="fS">點數紀錄</span>
          </a>
          <div class="lineV"></div>
          <a
            class="tab flexV width center"
            @click.prevent="$router.push('/point-list')"
          >
            <span class="fS">點數到期日</span>
          </a>
        </div>
        <!-- Filter -->
        <div class="filter flexH width">
          <a
            class="flexH width middle rounded"
            @click.prevent="timeFilterPopOpen = true"
          >
            <span class="fM">{{ displayedTimeFilterOption }}</span>
            <div class="icon third">
              <font-awesome-icon icon="fa-solid fa-caret-down" size="xs" />
            </div>
          </a>
          <div class="lineV"></div>
          <a
            class="flexH width middle rounded"
            @click.prevent="typeFilterPopOpen = true"
          >
            <span class="fM">{{ typeFilterOptions[filterByType] }}</span>
            <div class="icon third">
              <font-awesome-icon icon="fa-solid fa-caret-down" size="xs" />
            </div>
          </a>
        </div>
        <!-- 紀錄 -->
        <div class="records flexV width padding">
          <div
            v-for="(transaction, index) in displayTransactions"
            :key="'transaction' + index"
            @click.prevent="showTransactionDetail(index)"
          >
            <div class="record flexH width between margin">
              <div class="flexV width">
                <span class="fL">{{ $t(transaction.transactionType) }}</span>
                <span class="fM gray">{{ transaction.note }}</span>
              </div>
              <div class="flexV end">
                <div class="flexH">
                  <!-- <div class="img">
                    <img src="@/assets/icon/point.svg" />
                  </div> -->
                  <span :class="pointClass(transaction.entryType)"
                    >{{ displaySignOfPoint(transaction.entryType)
                    }}{{ transaction.pointAmount }}</span
                  >
                </div>
                <span class="fM gray end">{{
                  tsToDatetime(transaction.transactionTs)
                }}</span>
              </div>
              <div class="icon minor">
                <font-awesome-icon
                  icon="fa-solid fa-chevron-right"
                ></font-awesome-icon>
              </div>
            </div>
            <div class="lineH"></div>
          </div>
        </div>
      </section>
      <section class="footer point_history">
        <a class="button rounded" @click.prevent="$router.push('/scanner')">
          <div class="icon">
            <img src="@/assets/icon/scanner.svg" />
          </div>
          <span class="fM">使用{{ pointName }}</span>
        </a>
      </section>
      <!-- Popup (時間區間) -->
      <FilterPopup
        v-if="timeFilterPopOpen"
        @closePopup="timeFilterPopOpen = false"
        v-slot="pop"
        :popTime="true"
      >
        <section class="popHeader">
          <span class="fL">時間區間</span>
        </section>
        <section class="popMain">
          <!-- Tabs -->
          <div class="tabs rounded fS">
            <a
              :class="[
                'tab',
                'rounded',
                { active: timeFilterOption === 'Weekly' },
              ]"
              @click.prevent="changeTimeFilterOption('Weekly')"
            >
              <span>近一週</span>
            </a>
            <a
              :class="[
                'tab',
                'rounded',
                { active: timeFilterOption === 'Monthly' },
              ]"
              @click.prevent="changeTimeFilterOption('Monthly')"
            >
              <span>近一個月</span>
            </a>
            <a
              :class="[
                'tab',
                'rounded',
                { active: timeFilterOption === 'Yearly' },
              ]"
              @click.prevent="changeTimeFilterOption('Yearly')"
            >
              <span>近一年</span>
            </a>
            <a
              :class="[
                'tab',
                'rounded',
                { active: timeFilterOption === 'Customize' },
              ]"
              @click.prevent="changeTimeFilterOption('Customize')"
            >
              <span>自訂時間</span>
            </a>
          </div>
          <!-- Date -->
          <div class="input rounded" v-show="timeFilterOption == 'Customize'">
            <!-- class: fake / error -->
            <input
              :class="['fM', 'date', { error: selectStartErr }]"
              type="date"
              name="startDate"
              v-model="selectStart"
              @change="selectStartChange"
            />
            <!-- <input class="fM time" type="time" name="startTime"> -->
            <span class="fL">-</span>
            <input
              :class="['fM', 'date', { error: selectEndErr }]"
              type="date"
              name="endDate"
              v-model="selectEnd"
              @change="selectEndChange"
            />
          </div>
          <span v-if="timeFilterOption==='Customize' && (selectStartErr || selectEndErr)" class="error">
            {{ selectTimeErrMsg }} 
          </span>
         
          
        </section>
        <section class="popFooter">
          <a class="button rounded cancel white" @click.prevent="pop.close"
            ><span class="fM">取消</span></a
          >
          <a
            class="button rounded submit"
            @click.prevent="
              timeFilterConfirm();
              pop.close();
            "
            ><span class="fM">確認</span></a
          >
        </section>
      </FilterPopup>
      <!-- Popup (下拉選單) -->
      <FilterPopup
        v-if="typeFilterPopOpen"
        @closePopup="typeFilterPopOpen = false"
        v-slot="pop"
      >
        <section class="popHeader">
          <span class="fL">類型</span>
        </section>
        <section class="popMain" @click.prevent="pop.close">
          <a
            v-for="(name, type) in typeFilterOptions"
            :key="'type_' + type"
            :class="{ active: filterByType == type }"
            @click.prevent="changeTypeFilter(type)"
            ><span>{{ name }}</span></a
          >
        </section>
      </FilterPopup>
    </div>
     <!-- Popup (交易明細) -->
     <section
        id="popDetail"
        class="popup popDetail point_history"
        @click.prevent="orderDetailHide()"
      >
        <div class="popBody" @click.stop>
          <a class="close" @click.prevent="orderDetailHide()"
            ><font-awesome-icon icon="fas fa-times"></font-awesome-icon
          ></a>
          <!-- <pre>{{ transDetail }}</pre>  -->
          <template v-if="transDetail">
            <section class="popHeader">
                <span v-if="transDetail && transDetail.transactionType" class="fL">{{ $t(transDetail.transactionType) }}明細</span>
            </section>
            <section class="popMain">
               
                <div v-if="transDetail && transDetail.transactionType === 'Activity-Reward'" class="flexH width middle get">
                    <div class="img"><img src="@/assets/img/bg-w-store.png"></div>
                    <div class="flexV width">
                        <span class="fS">已獲得</span>
                        <span v-if="transDetail.paymentAmount" class="fXL bold success">NT${{ transDetail.paymentAmount }}</span>
                        <fragment v-if="transDetail.pointAmount">
                          <!-- <img src="@/assets/img/favicon.svg" /> -->
                          <span class="fXL bold success"> 點數 {{ transDetail.pointAmount }}</span>
                        </fragment>
                    </div>
                    
                    <div class="flexV end place">
                        <span class="fS width top">可用場所</span>
                        <span class="fS width"> {{ transDetail.customPublisher ? "指定特店" : "所有特店" }}</span>
                    </div>
                </div>
                <div v-else class="flexH width middle cost">
                  <span v-if="transDetail.paymentAmount" class="fXL bold"
                    >NT${{ transDetail.paymentAmount }}</span
                  >
                  <fragment v-if="transDetail.pointAmount">
                    <img v-if="transDetail.logo" :src="transDetail.logo">
                    <img v-else :src="pointImage" />
                    <span class="fXL bold primary">{{ transDetail.pointAmount }}</span>
                  </fragment>
                </div>
                <div v-if="transDetail  && transDetail.transactionType === 'Activity-Reward'" class="lineH"></div>
                <div class="flexH width between">
                  <span class="fM gray">交易時間</span>
                  <span class="fM">{{ tsToDatetime(transDetail.transactionTs) }}</span>
                </div>
                <!-- <div v-if="transDetail && transDetail.transactionType" class="flexH width between">
                  <span class="fM gray">交易類型</span>
                  <span class="fM">{{ $t(transDetail.transactionType) }}</span>
                </div> -->
                <div class="flexH width between">
                  <div class="img store_type">
                    <img v-if="transDetail.logo" :src="transDetail.logo">
                    <img v-else :src="pointImage">
                  </div>
                  <span class="fM gray width">{{ transDetail.title || pointName }}</span>
                  <span class="fM">{{ transDetail.pointAmount }}</span>
                </div>
                <div v-if="transDetail.note" class="lineH"></div>
                <div v-if="transDetail.note" class="flexV width between">
                  <span class="fM gray width">交易備註</span>
                  <span class="fM width"> {{ transDetail.note }} </span>
                </div>
                <fragment v-if="transDetail && transDetail.transactionType === 'Activity-Reward' && 
                  (transDetail.options && transDetail.options.description)">
                  <div class="lineH"></div>
                  <div 
                    v-for="(desc, index) in transDetail.options.description"
                    :key="'desc_' + index"
                    class="flexV width between" 
                  >
                  <span class="fM gray width"> {{ desc.title }}</span>
                  <span class="fM width" v-html="desc.content"> </span>
                </div>
                </fragment>
                <!-- <div v-if="transDetail && transDetail.entryType === 'D' && 
                  (transDetail.options && transDetail.options.description) " 
                  class="flexV width between">
                  <span class="fM width" v-html="transDetail.options.description"> </span>
                </div> -->
            </section>
          </template>
          <template v-else> 無交易明細 </template>

        </div>
      </section>
  </div>
</template>
<script>
import moment from 'moment-timezone';
import { mapState, mapGetters } from 'vuex';
import FilterPopup from '@/components/FilterPopup.vue';

export default {
  name: 'Record',
  components: { FilterPopup },
  data() {
    return {
      apiHost: process.env.VUE_APP_API_HOST,
      merchantId: process.env.VUE_APP_MERCHANT_ID,
      transactions: {},
      filterByType: 'A',
      originTransactions: {},
      timeFilterStart: null,
      timeFilterEnd: null,
      timeFilterOption: 'Monthly',
      displayedTimeFilterOption: '近一個月',
      selectStart: this.tsToDate(
        Math.floor(Date.now() / 1000) - 1 * 60 * 60 * 24 * 30
      ),
      selectEnd: this.tsToDate(Math.floor(Date.now() / 1000)),
      selectStartErr: false,
      selectEndErr: false,
      selectTimeErrMsg: '',
      typeFilterOptions: {
        A: '全部類型',
        D: '獲得點數',
        C: '點數消費',
      },
      timeFilterPopOpen: false,
      typeFilterPopOpen: false,
      transDetail: null
    };
  },
  beforeRouteEnter(to, from, next) {
    // called before the route that renders this component is confirmed.
    // does NOT have access to `this` component instance,
    // because it has not been created yet when this guard is called!
    if (from.path === '/profile') {
      to.meta.parent = '/profile';
    } else if (
      from.path === '/' ||
      from.path === '/record' ||
      from.path === '/point-list' ||
      from.path === '/payment-record'
    ) {
      to.meta.parent = localStorage.getItem('parentPath') || '/home';
    } else {
      to.meta.parent = '/home';
    }
    localStorage.setItem('parentPath', to.meta.parent);
    next((vm) => {
      let that = vm;
      // console.log('user ', that.user);
      if (that.user && that.user.userId) {
        //
      } else {
        next('/error');
      }
    });
  },
  created() {
    this.getUserPoints()
      .then((getUserPointsRes) => {
        console.log('getUserPointsRes: ', getUserPointsRes);
        this.$store.commit('updateUserPoints', getUserPointsRes);
        return this.getTransactions();
      })
      .then((getTransactionsRes) => {
        this.transactions = JSON.parse(JSON.stringify(getTransactionsRes));
        this.originTransactions = JSON.parse(
          JSON.stringify(getTransactionsRes)
        );
      })
      .catch((err) => {
        console.log('err: ', err);
        this.router.push('/error');
      });
  },
  mounted() {},
  computed: {
    ...mapState(['user']),
    ...mapGetters({
      userPoints: 'getTyUserPoints',
    }),
    enableConsumptionRecord() {
      return process.env.VUE_APP_DISABLE_CONSUMPTION_RECORD?.trim() === 'true' ? false : true;
    }, 
    pointImage() {
      return process.env.VUE_APP_POINT_IMAGE_PATH?.trim() ? require(`@/assets/${process.env.VUE_APP_POINT_IMAGE_PATH}`) : require("@/assets/icon/point.svg");
    }, 
    pointName() {
      return process.env.VUE_APP_POINT_NAME?.trim() ? process.env.VUE_APP_POINT_NAME?.trim() : '紅利桃子';
    }, 
    displayLastestEndDateOfPoints() {
      return this.lastestEndOfPoint
        ? this.tsToDate(this.lastestEndOfPoint.endTs)
        : '';

      // if (
      //     this.userPoints &&
      //     this.userPoints.pointPeriods &&
      //     this.userPoints.pointPeriods.length > 0
      // ) {
      //     return this.tsToDate(this.userPoints.pointPeriods[0].endTs);
      // } else {
      //     return "";
      // }
    },
    displayLastestBalanceOfPoints() {
      return this.lastestEndOfPoint ? this.lastestEndOfPoint.pointBalance : 0;
      // if (
      //     this.userPoints &&
      //     this.userPoints.pointPeriods &&
      //     this.userPoints.pointPeriods.length > 0
      // ) {
      //     return this.userPoints.pointPeriods[0].pointBalance;
      // } else {
      //     return 0;
      // }
    },
    displayPointBalance() {
      return this.userPoints && this.userPoints.pointBalance
        ? this.userPoints.pointBalance
        : 0;
    },
    displayTransactions() {
      if (this.transactions.records && this.transactions.records.length > 0) {
        return this.transactions.records;
      } else {
        return [];
      }
    },
    lastestEndOfPoint() {
      if (
        this.userPoints &&
        this.userPoints.pointPeriods &&
        this.userPoints.pointPeriods.length > 0
      ) {
        let sorted = [...this.userPoints.pointPeriods];
        sorted.sort(function (a, b) {
          return a.endTs - b.endTs;
        });
        const latestEndOfPoint = sorted.find((p) => p.endTs >= moment().unix());
        return latestEndOfPoint;
      }
      return null;
    },
  },
  watch: {
    filterByType() {
      this.filterByTypeHandler();
    },
  },
  methods: {
    goBackTo() {
      let parent = this.$route.meta.parent || '/home';
      this.$router.push(parent);
    },
    pointClass(entryType) {
      return [
        'fL',
        'bold',
        { error: entryType == 'C' },
        { success: entryType == 'D' },
      ];
    },
    displaySignOfPoint(entryType) {
      return entryType == 'D' ? '+' : '-';
    },
    tsToDatetime(ts) {
      return moment(ts * 1000).format('YYYY/MM/DD HH:mm:ss');
    },
    tsToDate(ts) {
      return moment(ts * 1000).format('YYYY-MM-DD');
    },
    getUserPoints() {
      var config = {
        method: 'get',
        url: `${this.apiHost}/points/v1/merchants/${this.merchantId}/members/${this.user.userId}/points`,
        headers: {},
      };

      return this.$http(config)
        .then(function (response) {
          return response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    dummyTransactions() {
      let result = {
        total: 3,
        records: [
          {
            entryId: 144,
            pointAmount: 10,
            entryType: 'C',
            note: '花錢',
            transactionId: 74,
            transactionEntityId: 270,
            transactionTs: 1650021907,
            transactionType: 'Consumption-Discount',
            merchantOrderId: 41,
          },
          {
            entryId: 146,
            pointAmount: 10,
            entryType: 'C',
            note: 'consumption',
            transactionId: 75,
            transactionEntityId: 270,
            transactionTs: 1650033376,
            transactionType: 'Consumption-Discount',
            merchantOrderId: 42,
          },
          {
            entryId: 148,
            pointAmount: 10,
            entryType: 'C',
            note: 'consumption',
            transactionId: 76,
            transactionEntityId: 270,
            transactionTs: 1650033478,
            transactionType: 'Consumption-Discount',
            merchantOrderId: 43,
          },
        ],
      };
      let promise = new Promise((resolve) => {
        setTimeout(() => {
          resolve({ data: result });
        }, 300);
      });
      return promise;
    },
    callTransactionsAPI(start, end) {
      let endTime = end || Math.floor(Date.now() / 1000);
      let startTime = start || endTime - 1 * 60 * 60 * 24 * 30;

      var config = {
        method: 'get',
        url: `${this.apiHost}/points/v1/merchants/${this.merchantId}/members/${this.user.userId}/points/${this.userPoints.pointCode}/ledgers?from_ts=${startTime}&to_ts=${endTime}`,
        headers: {},
      };
      return this.$http(config)
        .then(function (response) {
          return response;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getTransactions(start, end) {
      return (
        this.callTransactionsAPI(start, end)
          // return this.dummyTransactions()
          .then((res) => {
            return res.data;
          })
          .catch((err) => {
            console.log('err:', err);
          })
      );
    },
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/');
    },
    filterByTypeHandler() {
      switch (this.filterByType) {
        case 'A':
          this.transactions = JSON.parse(
            JSON.stringify(this.originTransactions)
          );
          break;
        case 'C':
          this.transactions.records = this.originTransactions.records.filter(
            (t) => t.entryType === 'C'
          );
          break;
        case 'D':
          this.transactions.records = this.originTransactions.records.filter(
            (t) => t.entryType === 'D'
          );
          break;
        default:
          break;
      }
    },
    filterByTimeHandler() {
      function tdesc(b, a) {
        return a.transactionTs - b.transactionTs;
      }
      function tasc(a, b) {
        return a.transactionTs - b.transactionTs;
      }
      function pdesc(b, a) {
        return a.pointAmount - b.pointAmount;
      }
      function pasc(a, b) {
        return a.pointAmount - b.pointAmount;
      }
      switch (this.filterbyTime) {
        case 'tdesc':
          this.transactions.records.sort(tdesc);
          break;
        case 'tasc':
          this.transactions.records.sort(tasc);
          break;
        case 'pdesc':
          this.transactions.records.sort(pdesc);
          break;
        case 'pasc':
          this.transactions.records.sort(pasc);
          break;
        default:
          break;
      }
    },
    timeFilterConfirm() {
      if (this.timeFilterOption == 'Customize') {
        console.log(this.selectStart);
        this.selectStartErr = this.selectStart ? false : true;
        this.selectEndErr = this.selectEnd ? false : true;
        if (this.selectStartErr || this.selectEndErr) return;
      }
      this.changeTimeFilterValue();
      let start = this.timeFilterStart;
      let end = this.timeFilterEnd;

      this.getTransactions(start, end)
        .then((getTransactionsRes) => {
          this.transactions = JSON.parse(JSON.stringify(getTransactionsRes));
          this.originTransactions = JSON.parse(
            JSON.stringify(getTransactionsRes)
          );

          this.filterByTypeHandler();
        })
        .catch((err) => {
          console.log('err: ', err);
          this.$router.push('/error');
        });
    },
    changeTimeFilterOption(option) {
      this.timeFilterOption = option;
    },
    changeTimeFilterValue() {
      switch (this.timeFilterOption) {
        case 'Weekly':
          this.timeFilterEnd = Math.floor(Date.now() / 1000);
          this.timeFilterStart = this.timeFilterEnd - 1 * 60 * 60 * 24 * 7;
          this.displayedTimeFilterOption = '近一週';
          break;
        case 'Monthly':
          this.timeFilterEnd = Math.floor(Date.now() / 1000);
          this.timeFilterStart = this.timeFilterEnd - 1 * 60 * 60 * 24 * 30;
          this.displayedTimeFilterOption = '近一個月';
          break;
        case 'Yearly':
          this.timeFilterEnd = Math.floor(Date.now() / 1000);
          this.timeFilterStart = this.timeFilterEnd - 1 * 60 * 60 * 24 * 365;
          this.displayedTimeFilterOption = '近一年';
          break;
        case 'Customize':
          this.timeFilterEnd =
            this.dateEndToTs(this.selectEnd) > Date.now() / 1000
              ? Date.now() / 1000
              : this.dateEndToTs(this.selectEnd);
          this.timeFilterStart = this.dateStartToTs(this.selectStart);
          this.displayedTimeFilterOption = '自訂時間';
          break;
        default:
          break;
      }
    },
    dateToTs(date) {
      return new Date(date).getTime() / 1000;
    },
    dateStartToTs(date) {
      return new Date(date).setHours(0, 0, 0, 0) / 1000;
    },
    dateEndToTs(date) {
      return new Date(date).setHours(23, 59, 59, 999) / 1000;
    },
    selectStartChange() {
      this.selectStartErr = this.selectStart ? false : true;
      this.selectEndErr = this.selectEnd ? false : true;
      
      if (this.selectStartErr) {
        this.selectTimeErrMsg = '開始日期/結束日期為必填';
        return;
      }
      if (new Date(this.selectStart) > new Date(this.selectEnd)) {
        this.selectStartErr = true;
        this.selectTimeErrMsg = '開始日期須小於結束日期';
      }
    },
    selectEndChange() {
      this.selectStartErr = this.selectStart ? false : true;
      this.selectEndErr = this.selectEnd ? false : true;
       if (this.selectEndErr) {
        this.selectTimeErrMsg = '開始日期/結束日期為必填';
        return;
      }
      if (new Date(this.selectStart) > new Date(this.selectEnd)) {
        this.selectEndErr = true;
        this.selectTimeErrMsg = '開始日期須小於結束日期';
      }
    },
    changeTypeFilter(type) {
      this.filterByType = type;
    },
    showTransactionDetail(idx) {
      popDetail.style.display = 'flex';
      this.transDetail = this.displayTransactions[idx];
      // this.transDetail = this.displayTransactions.find(
      //   (order) => order.transactionId === id
      // );
    },
    orderDetailHide() {
      popDetail.style.display = 'none';
    },
  },
};
</script>
